@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.parent-li:hover>a {
    color: gray;
}

.outline-text {
    -webkit-text-stroke: 1px #f8bc84;
}

ul.primary li:hover ul {
    display: block;
}

ul.primary li:active ul {
    display: block;
}


div.profileCard:hover div.profilePopup {
    display: block;
}

/* the popup doesnt even show on screen with 1024 width or less */
@media (max-width: 1024px) {
    div.profileCard:hover div.profilePopup {
        display: none;
    }
}

.bg-gradient {
    /* background: linear-gradient(to bottom, #c83474 0%,#f0645c 40%, #f8bc84 100%); */
    background: #f0645c;
}

.content{
    min-height: calc(100vh - 80px);
}

